<template>
  <div
    class="MemolanguageFlytogetLanguagesView"
    :class="{ 'is-loading': selectedLanguageId }">
    <div
      v-if="isAuthenticated"
      class="avatar --global-clickable"
      :class="{ 'is-tempuser': isTempUser }"
      :style="{ backgroundImage: `url(${avatarImage})` }"
      @click="showingDropdown = true" />
    <div
      v-else
      v-t="'signIn'"
      class="signinButton --global-clickable"
      @click="gotoLogin()" />

    <template v-if="showingDropdown">
      <div
        class="dropdownMenuBlockingLayer"
        @click="showingDropdown = false" />
      <div class="dropdownMenu">
        <div
          class="--global-clickable"
          @click="logout()">
          Logout
        </div>
      </div>
    </template>

    <div
      class="affiliateIcon"
      data-affiliate-suffix="${affiliatePrefix}" />

    <div class="spacerTop1" />

    <div
      v-t="'listHeader'"
      class="listHeader" />

    <div class="spacerTop" />

    <div class="languages">
      <div
        v-for="languageId of availableLanguages"
        :key="languageId"
        class="Language --global-clickable"
        :class="{ 'is-selected': selectedLanguageId === languageId }"
        @click="gotoLanguage(languageId)">
        <div
          class="Language__icon"
          :style="{ backgroundImage: `url(${getFlagImage(languageId)})` }" />
        <div
          v-t="`memolanguage.languageLabels.${languageId}`"
          class="Language__label" />
        <svgicon
          v-if="selectedLanguageId === languageId"
          class="Language__loadwheel"
          icon="loadwheel" />
      </div>
    </div>

    <div
      v-if="sourceLanguage === 'english'"
      class="switchBaseLanguage --global-clickable"
      @click="setSourceLanguage('norwegian')">
      {{ $t('switchBaseLanguage', { language: 'norwegian' }) }}
    </div>
    <div
      v-if="sourceLanguage === 'norwegian'"
      class="switchBaseLanguage --global-clickable"
      @click="setSourceLanguage('english')">
      {{ $t('switchBaseLanguage', { language: 'english' }) }}
    </div>

    <div class="spacer" />

    <div class="madeWithMemolife">
      <span>Made with</span>
      <svgicon
        class="svg-icon"
        icon="heart" />
      <span>memolife</span>
    </div>
  </div>
</template>

<translations>
  listHeader: 'I want to learn ...'
  listHeader_no: 'Jeg vil lære ...'

  signIn: 'Sign in'
  signIn_no: 'Logg inn'

  switchBaseLanguage: 'Endre til norsk base-språk'
  switchBaseLanguage_no: 'Change to English base language'
</translations>

<script>
const images = require.context('@/assets/images/', true, /\.(png|jpg)$/);

export default {
    data() {
        return {
            showingDropdown: false,
            selectedLanguageId: null
        };
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.moduleAuth.isAuthenticated;
        },
        isTempUser() {
            return this.$store.getters['moduleAuth/isTempUser'];
        },
        avatarImage() {
            if (this.isAuthenticated) {
                return this.$store.state.moduleAuth.profile.avatar;
                // || '/memolanguage/images/tempuser-avatar.png
            } else {
                return '';
            }
        },
        availableLanguages() {
            return this.$store.getters['moduleApp/availableLanguagesFlytoget'];
        },
        sourceLanguage() {
            return this.$store.getters['moduleApp/locale'] === 'no' ? 'norwegian' : 'english';
        }
    },
    methods: {
        gotoSignup() {
            this.$router.push({ name: 'MemolanguageFlytogetSignupView' });
        },
        async gotoLanguage(languageId) {
            this.selectedLanguageId = languageId;
            const userLanguage = { english: 'en', norwegian: 'no' }[this.sourceLanguage];

            if (this.isAuthenticated) {
                await this.$store.dispatch('moduleAuth/updateMe', { roles: ['flytoget'] });
            } else {
                await this.$store.dispatch('moduleAuth/createTempUser', {
                    affiliate: 'flytoget',
                    currency: 'nok',
                    language: userLanguage
                });
            }
            this.$router.push(`/memolanguage/${languageId}/home`);
        },
        getFlagImage(languageId) {
            try {
                return images(`./flags/${languageId}.png`);
            } catch (err) {
                return null;
            }
        },
        gotoLogin() {
            this.$router.push({ name: 'AccountLoginView', query: { next: '/memolanguage/flytoget/languages' } });
        },
        setSourceLanguage(language) {
            const languageCode = { english: 'en', norwegian: 'no' }[language];
            this.$store.dispatch('moduleApp/setLocale', languageCode);
        },
        async logout() {
            await this.$store.dispatch('moduleAuth/logout');
            location.reload();
        }
    }
};
</script>

<style lang="scss" scoped>
$loadingFadeDuration: 0.3s;
$whiteColor: white;
$flytogetOrange: orange;

.MemolanguageFlytogetLanguagesView {
    background: $memolanguage-flytogetBackgroundGradient;
    color: $whiteColor;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2em 2em;
    padding-top: 0em;
    padding-bottom: 0em;
    overflow-y: auto;

    &.is-loading {
        pointer-events: none; // Disabling interaction
    }
}

.spacerTop1 {
    height: 1.5em;
    min-height: 1em;
}
.spacerTop {
    height: 3em;
    min-height: 1em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}

// .#{$ME} .#{$ME}__signupBanner {
//   position: absolute;
//   left: 0;
//   height: 3em;
//   top: 0;
// }

.affiliateIcon {
    $size: 4em;
    width: $size;
    height: $size;
    background-color: $memolanguage-flytogetOrange;

    background-image: url('../aurelia/images/flytoget-square.jpg');
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    flex-shrink: 0;
}

.listHeader {
    font-size: 120%;
    color: rgba(white, 0.9);
}

.languages {
    width: 100%;
    max-width: 20em;
}

.Language {
    display: flex;
    align-items: center;
    padding: 0.5em;
    padding-right: 1em;
    background-color: white;
    border-radius: 2em;
    transition: opacity $loadingFadeDuration;
    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
}
.MemolanguageFlytogetLanguagesView.is-loading {
    .Language:not(.is-selected) {
        opacity: 0.2;
    }
}

.Language__icon {
    $size: 2.2em;
    width: $size;
    height: $size;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin-right: 1em;
}
.Language__label {
    color: mix(white, black, 30%);
    font-weight: 300;
    flex: 1;
    display: flex;
    align-items: center;

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: mix(white, black, 30%);
        margin: 0 0.4em;
    }
}
.Language__loadwheel {
    $size: 1.4em;
    width: $size;
    height: $size;
    fill: mix(white, black, 20%);

    animation: rotate360 2s linear infinite;
}
@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.signinButton {
    padding: 1em 0;
    color: rgba(white, 0.8);
    font-weight: 300;
    transition: opacity $loadingFadeDuration;

    align-self: flex-end;
}
.MemolanguageFlytogetLanguagesView.is-loading .signinButton {
    opacity: 0.2;
}

.avatar {
    $size: 2em;
    width: $size;
    height: $size;

    background-color: white;

    background-size: cover;
    background-position: center;
    border-radius: 50%;

    margin-top: 0.5em;
    margin-bottom: 0.5em;

    align-self: flex-end;
    flex-shrink: 0;

    &.is-tempuser {
        visibility: hidden; // Should be part of layout
        pointer-events: none;
    }
}

.dropdownMenuBlockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.dropdownMenu {
    position: absolute;
    top: 3em;
    right: 2em;
    background-color: white;
    color: rgba(black, 0.8);
    & > * {
        padding: 0.5em 1em;
    }
}

.switchBaseLanguage {
    color: rgba(white, 0.8);
    font-weight: 300;
    padding: 1em 0;
    font-size: 90%;

    margin-top: 1em;
}
.MemolanguageFlytogetLanguagesView.is-loading .switchBaseLanguage {
    opacity: 0.2;
}

.madeWithMemolife {
    font-size: 90%;
    display: flex;
    align-items: center;
    color: rgba(white, 0.7);
    font-weight: 300;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.7);
        margin: 0 0.3em;
    }

    padding-bottom: 2em;
}
</style>
